@use 'abstracts/variables';
@use 'abstracts/colors';

.resource-planning-board-grid {
  .ag-header-cell {
    justify-content: center;
    padding: 0;
  }
}

.ag-group-contracted,
.ag-group-expanded {
  width: 10px;
}

.ag-group-value {
  flex: 1;
}

.ag-cell-wrapper {
  height: 100%;
}

.portal-fix-ag-cell-line-height {
  p {
    line-height: 25px !important;
  }
}

.ag-cell {
  .portal-show-when-focus {
    display: none;
  }

  &.ag-cell-focus {
    .portal-show-when-focus {
      display: unset;
    }
  }

  &.portal-fill-center {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  &.portal-assignment-period-cell.ag-cell-focus {
    border-color: darkgray !important;
  }

  &.portal-ag-cell-card.ag-cell-focus {
    border: solid variables.$border-width-sm transparent !important; // same border as unfocused cell
  }
}

.ag-row-level-0 .ag-cell,
.ag-row-level-1 .ag-cell,
.ag-row-level-2 .ag-cell,
.ag-row-level-3 .ag-cell {
  padding: 0;
}

.ag-row-level-1 .ag-cell .ag-cell-wrapper .ag-group-value {
  flex: 1;
}

.ag-header-cell-comp-wrapper {
  height: 100%;
  justify-content: center;
}

.ag-row-group {
  .ag-cell.ag-cell-focus {
    border: solid variables.$border-width-sm transparent !important;
  }

  .portal-resource-cell {
    display: flex;
    align-items: center;

    .ag-cell-wrapper {
      width: 100%;
    }
  }
}

.p-use-custom-expand-button {
  .ag-row-group {
    .ag-group-contracted,
    .ag-group-expanded {
      display: none;
    }
  }
}

.ag-row-level-0 .ag-cell {
  .ag-cell-wrapper {
    height: 100%;

    .ag-group-contracted,
    .ag-group-expanded,
    .ag-group-value {
      align-self: center;
    }
  }
}

.resource-planning-board-grid.unassigned-grid {
  .ag-cell-focus,
  .ag-cell {
    border: solid variables.$border-width-sm transparent !important;
  }
}

.pl-table-group {
  background-color: colors.$color-bg;
}
