@use 'abstracts/variables';
@use 'abstracts/colors';

/*
This file contains animation and transition mixins and functions
*/

$default-duration: variables.$animation-speed;
$loading-animation-width: 200px;

@mixin transition-ease-in-out($property: all, $duration: $default-duration, $delay: 0s) {
  transition: $property ease-in-out $duration $delay;
}

@mixin transition-ease($property: all, $duration: $default-duration, $delay: 0s) {
  transition: $property ease $duration $delay;
}

@mixin transition-cubic($property: all, $duration: $default-duration, $delay: 0s) {
  transition: $property cubic-bezier(0.4, 0, 0.2, 1) $duration $delay;
}

@keyframes progress {
  0% {
    transform: translate3d(-$loading-animation-width, 0, 0);
  }

  100% {
    transform: translate3d(calc($loading-animation-width + 100vw), 0, 0);
  }
}

@mixin skeleton-animation {
  animation: progress 2s ease-in-out infinite;
  background-size: $loading-animation-width 100%;
  display: block;
  z-index: 1;
  top: 0;
  left: 0;
  width: $loading-animation-width;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    90deg,
    colors.$color-loading-animation1,
    colors.$color-loading-animation2,
    colors.$color-loading-animation1
  ) !important;
}
