@use '@angular/material' as mat;
@use 'abstracts/colors';
@use 'sass:color';
@use 'sass:map';
@import '@angular/cdk/overlay-prebuilt.css';

// Note: Color palettes are generated from primary: #179777
$color-palettes: (
  primary: (
    0: #000,
    10: #002117,
    20: #00382a,
    25: #004434,
    30: #00513e,
    35: #005e48,
    40: #006c53,
    50: #008769,
    60: #19a381,
    70: #43bf9b,
    80: #63dbb5,
    90: #a7e6d3,
    95: #baffe4,
    98: #e7fff3,
    99: #f4fff8,
    100: #fff
  ),
  secondary: (
    0: #000,
    10: #181818,
    20: #2e2e2e,
    25: #393939,
    30: #454545,
    35: #535353,
    40: #5f5f5f,
    50: #7d7d7d,
    60: #979797,
    70: #b5b5b5,
    80: #cdcdcd,
    90: #eaeaea,
    95: #f8f8f8,
    98: #f8f8f8,
    99: #fbfbfb,
    100: #fff
  ),
  tertiary: (
    0: #000,
    10: #001e2b,
    20: #0c3445,
    25: #1a3f51,
    30: #274b5d,
    35: #335769,
    40: #406376,
    50: #597c8f,
    60: #7295aa,
    70: #8cb0c5,
    80: #a7cce1,
    90: #c3e8fe,
    95: #e2f3ff,
    98: #f5faff,
    99: #fbfcff,
    100: #fff
  ),
  neutral: (
    0: #000,
    10: #1d1d1d,
    20: #323232,
    25: #3e3e3e,
    30: #484848,
    35: #545454,
    40: #5e5e5e,
    50: #7a7a7a,
    60: #919191,
    70: #ababab,
    80: #c7c7c7,
    90: #e2e2e2,
    95: #f1f1f1,
    98: #f9f9f9,
    99: #fbfbfb,
    100: #fff,
    4: #0f0f0f,
    6: #141414,
    12: #222,
    17: #2b2b2b,
    22: #343434,
    24: #393939,
    87: #dcdcdc,
    92: #eaeaea,
    94: #ededed,
    96: #f1f1f1
  ),
  neutral-variant: (
    0: #000,
    10: #151d1a,
    20: #29322e,
    25: #343d39,
    30: #454548,
    35: #4b5550,
    40: #57615c,
    50: #707974,
    60: #89938e,
    70: #a4ada8,
    80: #bfc9c3,
    90: #fff,
    95: #e9f3ed,
    98: #f2fcf5,
    99: #f5fff8,
    100: #fff
  ),
  error: (
    0: #000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #fff
  )
);
$rest: (
  secondary: map.get($color-palettes, secondary),
  neutral: map.get($color-palettes, neutral),
  neutral-variant: map.get($color-palettes, neutral-variant),
  error: map.get($color-palettes, error)
);
$primary: map.merge(map.get($color-palettes, primary), $rest);
$tertiary: map.merge(map.get($color-palettes, tertiary), $rest);

body {
  @include mat.core;

  $light-theme: mat.define-theme(
    (
      color: (
        theme-type: light,
        primary: $primary
      ),
      typography: (
        brand-family: 'roboto',
        bold-weight: 900
      ),
      density: (
        scale: -1
      )
    )
  );

  @include mat.all-component-themes($light-theme);

  &.dark-theme {
    $dark-theme: mat.define-theme(
      (
        color: (
          theme-type: dark,
          primary: $primary
        ),
        typography: (
          brand-family: 'roboto',
          bold-weight: 900
        ),
        density: (
          scale: -1
        )
      )
    );

    @include mat.all-component-colors($dark-theme);
  }
}
