@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/helpers';
@use '@ag-grid-community/styles' as ag-grid;

@include ag-grid.grid-styles(
  (
    theme: alpine,

    --ag-icon-font-family: 'Font Awesome 6 Pro',
    --ag-icon-font-code-aggregation: '\f247',
    --ag-icon-font-code-arrows: '\f0b2',
    --ag-icon-font-code-asc: '\f062',
    --ag-icon-font-code-cancel: '\f057',
    --ag-icon-font-code-chart: '\f080',
    --ag-icon-font-code-color-picker: '\f576',
    --ag-icon-font-code-columns: '\f0db',
    --ag-icon-font-code-contracted: '\f146',
    --ag-icon-font-code-copy: '\f0c5',
    --ag-icon-font-code-cross: '\f00d',
    --ag-icon-font-code-desc: '\f063',
    --ag-icon-font-code-expanded: '\f0fe',
    --ag-icon-font-code-eye-slash: '\f070',
    --ag-icon-font-code-eye: '\f06e',
    --ag-icon-font-code-filter: '\f0b0',
    --ag-icon-font-code-first: '\f100',
    --ag-icon-font-code-grip: '\f58e',
    --ag-icon-font-code-group: '\f5fd',
    --ag-icon-font-code-last: '\f101',
    --ag-icon-font-code-left: '\f060',
    --ag-icon-font-code-linked: '\f0c1',
    --ag-icon-font-code-loading: '\f110',
    --ag-icon-font-code-maximize: '\f2d0',
    --ag-icon-font-code-menu: '\f0c9',
    --ag-icon-font-code-minimize: '\f2d1',
    --ag-icon-font-code-next: '\f105',
    --ag-icon-font-code-none: '\f338',
    --ag-icon-font-code-not-allowed: '\f05e',
    --ag-icon-font-code-paste: '\f0ea',
    --ag-icon-font-code-pin: '\f276',
    --ag-icon-font-code-pivot: '\f074',
    --ag-icon-font-code-previous: '\f104',
    --ag-icon-font-code-right: '\f061',
    --ag-icon-font-code-save: '\f0c7',
    --ag-icon-font-code-small-down: '\f107',
    --ag-icon-font-code-small-left: '\f104',
    --ag-icon-font-code-small-right: '\f105',
    --ag-icon-font-code-small-up: '\f106',
    --ag-icon-font-code-tick: '\f00c',
    --ag-icon-font-code-tree-closed: '\f0da',
    --ag-icon-font-code-tree-indeterminate: '\f068',
    --ag-icon-font-code-tree-open: '\f0d7',
    --ag-icon-font-code-unlinked: '\f127',

    --ag-background-color: colors.$color-bg-light,
    --ag-border-color: colors.$color-bg-dark,

    --ag-checkbox-background-color: colors.$color-bg,
    --ag-checkbox-checked-color: colors.$color-bg-dark,
    --ag-checkbox-unchecked-color: colors.$color-bg-light,
    --ag-checkbox-indeterminate-color: colors.$color-bg,

    --ag-chip-background-color: colors.$color-bg-dark,

    --ag-control-panel-background-color: colors.$color-bg-dark,

    --ag-data-color: colors.$color-text,

    --ag-disabled-foreground-color: colors.$color-grey,

    --ag-foreground-color: colors.$color-text,

    --ag-header-background-color: colors.$color-white,
    --ag-header-cell-hover-background-color: colors.$color-white,

    --ag-header-cell-moving-background-color: colors.$color-bg-light,
    --ag-header-column-separator-color: colors.$color-bg-light,
    --ag-header-column-resize-handle-color: colors.$color-bg-light,

    --ag-input-border-color: colors.$color-black,
    --ag-input-disabled-border-color: colors.$color-grey,
    --ag-input-disabled-background-color: colors.$color-grey,
    --ag-input-focus-border-color: colors.$color-black,

    --ag-minichart-selected-chart-color: colors.$color-black,
    --ag-minichart-selected-page-color: colors.$color-black,

    --ag-modal-overlay-background-color: colors.$color-bg,

    --ag-odd-row-background-color: colors.$color-bg-light,

    --ag-range-selection-border-color: colors.$color-black,
    --ag-range-selection-background-color: colors.$color-white,
    --ag-range-selection-background-color-1: colors.$color-bg-dark,
    --ag-range-selection-background-color-2: colors.$color-bg-dark,
    --ag-range-selection-background-color-3: colors.$color-bg-dark,
    --ag-range-selection-background-color-4: colors.$color-bg-dark,
    --ag-range-selection-highlight-color: colors.$color-bg-dark,
    --ag-range-selection-chart-category-background-color: colors.$color-bg-dark,
    --ag-range-selection-chart-background-color: colors.$color-bg-dark,

    --ag-row-hover-color: transparent,
    --ag-row-border-color: colors.$color-bg-dark,

    --ag-secondary-border-color: colors.$color-bg-dark,
    --ag-secondary-foreground-color: colors.$color-text,

    --ag-selected-row-background-color: colors.$color-bg,
    --ag-selected-tab-underline-color: colors.$color-bg,

    --ag-subheader-background-color: colors.$color-bg-dark,
    --ag-subheader-toolbar-background-color: colors.$color-bg-dark,

    --ag-toggle-button-off-border-color: colors.$color-bg-dark,
    --ag-toggle-button-off-background-color: colors.$color-bg-light,
    --ag-toggle-button-on-border-color: colors.$color-bg-dark,
    --ag-toggle-button-on-background-color: colors.$color-bg-light,
    --ag-toggle-button-switch-background-color: colors.$color-bg-light,
    --ag-toggle-button-switch-border-color: colors.$color-bg-dark,

    --ag-value-change-value-highlight-background-color: colors.$color-bg-dark,
    --ag-value-change-delta-up-color: colors.$color-progressbar-green-done,
    --ag-value-change-delta-down-color: colors.$color-progressbar-red-done
  )
);

@mixin ag-grid-customization {
  .ag-root {
    .ag-cell {
      transition: all variables.$animation-speed ease 0s;
    }

    .ag-header-cell {
      transition: all variables.$animation-speed-long ease 0s;
    }

    .ag-icon {
      // required because Font Awesome uses bold for its icons
      font-weight: bold;
    }

    .ag-pinned-left-header {
      border: none;
    }

    @include helpers.portal-scroll-bar;
  }
}

.ag-theme-alpine {
  @include ag-grid-customization;

  --ag-alpine-active-color: var(--color-grey800);

  .ag-layout-auto-height .ag-center-cols-viewport,
  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-print .ag-center-cols-viewport,
  .ag-layout-print .ag-center-cols-container {
    min-height: 0;
  }
}

.ag-center-cols-clipper {
  min-height: unset !important;
}
