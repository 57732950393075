/*
This file contains breakpoint mixins
*/

@use 'abstracts/variables';

// --breakpoint-mobile: 320px;
// --breakpoint-tablet: 768px;
// --breakpoint-desktop: 1024px;
// --breakpoint-desktop-hd: 1440px;

@mixin breakpoint-mobile-down {
  @media screen and (max-width: variables.$breakpoint-mobile) {
    @content;
  }
}

@mixin breakpoint-tablet-down {
  @media screen and (max-width: variables.$breakpoint-tablet) {
    @content;
  }
}

@mixin breakpoint-desktop-down {
  @media screen and (max-width: variables.$breakpoint-desktop) {
    @content;
  }
}

@mixin breakpoint-desktop-hd-down {
  @media screen and (max-width: variables.$breakpoint-desktop-hd) {
    @content;
  }
}

/*
*
* Container breakpoints
*
*/

@mixin container-period-column {
  @container (max-width: #{variables.$breakpoint-period-column-small}) {
    @content;
  }
}

@mixin container-day-column {
  @container (max-width: #{variables.$breakpoint-day-column-small}) {
    @content;
  }
}
