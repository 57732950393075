/*
This file contains various global SCSS utility classes.
It should NOT contain abstract variables, helpers or functions, only direct outputs (classes, placeholders).
*/

@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/helpers';

// brand-name-image var
$brand-logo-offset: 50px;
$brand-logo-size: 115px;
$brand-logo-aspect-ratio: 752/146; // svg viewport

// some css display properties collide with the hidden attribute.
*[hidden] {
  display: none !important;
}

.portal-clickable {
  cursor: pointer;
}

.portal-padding-0 {
  padding: 0 !important;
}

.portal-margin-btm-sm-0 {
  margin-bottom: variables.$spacing-sm-0;
}

.portal-text-ellipsis {
  @include helpers.text-ellipsis;
}

// Change 5 to the maximum value needed in the app
@for $nb from 1 through 5 {
  .portal-line-clamp-#{$nb} {
    @include helpers.line-clamp($nb);
  }
}

.bg-image path {
  fill: colors.$color-bg;
}

.main-logo {
  background-image: url('../../assets/logos/jovaco_co.svg');
  aspect-ratio: $brand-logo-aspect-ratio;
}

.brand-name-image {
  background-image: url('../../assets/logos/jovaco.svg');
  aspect-ratio: $brand-logo-aspect-ratio;
  background-size: contain;
  background-repeat: no-repeat;

  &.bottom-right {
    position: absolute;
    bottom: $brand-logo-offset;
    right: $brand-logo-offset;
    width: $brand-logo-size;
    background-position: bottom right;
  }
}

.viewport .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
