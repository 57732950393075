/*
This file contains custom navigation elements styling
*/

@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/helpers';
@use 'abstracts/animations';
@use 'abstracts/fonts';

$-logo-width: 64px;
$-logo-height: 32px;
$-action-button-diamater: 46px;

.nav-logo {
  height: $-logo-height;
  width: $-logo-width;
  flex: 0 0 $-logo-width;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-title {
  font-weight: bold;

  @include helpers.text-ellipsis;
}

.nav-sub-title {
  font-weight: bold;

  @include helpers.text-ellipsis;
}

.nav-title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: variables.$iconsize-md;
  height: $-action-button-diamater;
  width: $-action-button-diamater;
  flex: 0 0 $-action-button-diamater;
  background: colors.$color-bg-light;
  border-radius: 50%;
}

.nav-back-button {
  height: $-action-button-diamater;
  width: $-action-button-diamater;
  border-radius: 50%;
  color: colors.$color-primary;
  background-color: colors.$color-bg;

  .btn-icon {
    font-size: variables.$iconsize-l;
  }
}

.nav-icon-btn {
  font-size: variables.$iconsize-md;
  cursor: pointer;
}

.nav-close-btn {
  height: $-action-button-diamater;
  width: $-action-button-diamater;
  border-radius: 50%;

  .btn-icon {
    @include animations.transition-ease-in-out(transform);
  }

  &:hover > .btn-icon {
    transform: rotate(180deg);
  }
}
