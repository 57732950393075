/*
This file is used to contain all vendor subfolder imports. Those files should mainly override external librairy styles.
The vendor styles themselves should be imported through the angular.json file.
*/

// Import Vendor files
@forward 'ag-grid/global-overrides';
@forward 'ag-grid/ag-cell';
@forward 'ag-grid/ag-row';
@forward 'ag-grid/ag-indent';
@forward 'font-awesome/font-awesome-overrides';
@forward 'material/mat-theme-overrides';
@forward 'material/mat-tooltip-overrides';
