/*
This file contains custom boxes styling
*/

@use 'abstracts/colors';
@use 'abstracts/variables';

.portal-box {
  border-radius: variables.$border-radius;
  color: colors.$color-text;

  &-square {
    border-radius: 0;
  }

  &-border {
    border: solid variables.$border-width-sm colors.$color-primary;
  }

  &-padding {
    padding: variables.$spacing-sm-1;
  }

  &-solid {
    background-color: colors.$color-primary;
    color: colors.$color-text-reverse;
  }

  &.draggable {
    &.portal-drag-ready {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      &:hover {
        box-shadow: variables.$card-box-shadow;
        filter: brightness(0.95);
      }
    }

    &.portal-dragging {
      opacity: 0.6;
    }
  }
}

.cdk-drag-preview {
  background: colors.$color-bg;
  border-radius: variables.$border-radius;
  padding: variables.$spacing-sm-2 variables.$spacing-sm-1;
  overflow: hidden;
}
