/*
 *
 * Default Theme
 * Please update other theme files if anything is added or removed.
 *
 */

:root {
  --color-green100: #a7e6d3;
  --color-green300: #14a883;
  --color-green500: #179777;

  --color-purple100: #a396f0;
  --color-purple300: #8a7dd7;
  --color-purple500: #675ca8;

  --color-orange100: #ffecb3;
  --color-orange300: #ffb74d;

  --color-amber100: #ffecb3;
  --color-amber300: #ffd54f;
  --color-amber500: #ffc107;

  --color-red100: #ff9696;
  --color-red300: #f1685e;

  --color-black: #000;
  --color-white: #fff;

  --color-grey800: #616161;
  --color-grey600: #9f9f9f;
  --color-grey300: #e8e9eb;
  --color-grey200: #f1f1f3;
  --color-grey100: #fafafa;

  --color-shadow: rgba(0 0 0 / 50%);

  // change the animation color for the skeleton
  --color-loading-gradient1: hsl(0deg 0% 100% / 0%);
  --color-loading-gradient2: hsl(0deg 0% 100% / 60%);

  --color-new: #bdbdbd;
  --color-waiting-approval: #e4a11e;
  --color-refused: #cb413f;
  --color-approved: #6db82c;
  --color-transferred: #a5308b;
  --color-transferred-no-approval: #6375d6;
  --color-edited-after-transfer: #3c3c3c;
}
