/*
This file contains custom containers styling
*/

@use 'abstracts/variables';
@use 'abstracts/colors';

.portal-overlay {
  background-color: colors.$color-bg-overlay;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: variables.$overlay-z-index;
}

// Flex containers utility classes
.portal-flex-container-full {
  display: flex;
  height: 100%;
  width: 100%;
}

.portal-absolute-fill {
  position: absolute;
  inset: 0;
}

.portal-fill {
  width: 100%;
  height: 100%;
}

.portal-flex-fill {
  flex: 1;
  min-width: 0;
}

.portal-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.portal-none {
  display: none;
}
