/*
This file contains base class styling
*/

@use 'abstracts/colors';
@use 'abstracts/helpers';

body {
  color: colors.$color-text;
  background: colors.$color-bg-light;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  @include helpers.portal-scroll-bar;
}

portal-root,
tedi-root,
al-root,
cp-root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
