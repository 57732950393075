/*
This file contains color variables
*/

// Main

body:has(> tedi-root) {
  --color-primary: var(--color-purple500);
  --color-primary-light: var(--color-purple300);
}

body:has(> al-root) {
  --color-primary: var(--color-green500);
  --color-primary-light: var(--color-green300);
}

body:has(> cp-root) {
  --color-primary: var(--color-amber300);
  --color-primary-light: var(--color-amber100);
}

$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$color-secondary: var(--color-orange300);
$color-accent: var(--color-red300);

// Backgrounds
$color-bg-light: var(--color-grey100);
$color-bg: var(--color-grey200);
$color-bg-dark: var(--color-grey300);
$color-bg-overlay: rgba(0 0 0 / 50%);

// Basics
$color-white: var(--color-white);
$color-black: var(--color-black);
$color-white-always: #fff;
$color-black-always: #000;
$color-grey: var(--color-grey600);
$color-red: var(--color-red300);
$color-red-light: var(--color-red100);
$color-green: var(--color-green300);
$color-green-light: var(--color-green100);
$color-orange: var(--color-orange300);

// Text
$color-text: var(--color-grey800);
$color-text-reverse: $color-white;
$color-text-light: $color-grey;

// Progress bar
$color-progressbar-empty: var(--color-grey300);
$color-progressbar-text: rgba($color-black-always, 50%);
$color-progressbar-text-empty: $color-text-light;
$color-progressbar-green-done: var(--color-green500);
$color-progressbar-green-todo: $color-green-light;
$color-progressbar-yellow-done: var(--color-orange300);
$color-progressbar-yellow-todo: var(--color-orange100);
$color-progressbar-red-done: var(--color-red300);
$color-progressbar-red-todo: var(--color-red100);

// drag n drop
$color-dropzone-over: $color-green-light;
$color-dropzone: var(--color-grey300);

// shadow
$box-shadow: var(--color-shadow);

// animations
$color-loading-animation1: var(--color-loading-gradient1);
$color-loading-animation2: var(--color-loading-gradient2);

// integrations

$color-microsoft-teams: #5b5fc7;


// Advanced Options
$advanced-options-dark-green: #259076;
$advanced-options-background-light-green: #d1f2e8;
$advanced-options-background-darker-light-green: #a7e6d3;
$advanced-options-background-yellow: #fffaec;
$advanced-options-dark-text: #616161;
$advanced-options-input-background: #e8e9eb;