@use 'abstracts/colors';
@use 'abstracts/variables';

.sk-circle {
  width: variables.$iconsize-l;
  height: variables.$iconsize-l;
  border-radius: 50%;
  animation: flash 2s infinite;
}
@keyframes flash {
  from {
    background-color: colors.$color-bg-light;
  }

  to {
    background-color: colors.$color-bg-dark;
  }
}
