@use 'abstracts/colors';

/*
This file contains all public variables for the project
*/

/*
 *
 * Icons
 *
 */

$iconsize-xs: 8px;
$iconsize-sm: 16px;
$iconsize-md: 24px;
$iconsize-l: 32px;
$iconsize-xl: 40px;

/*
 *
 * Breakpoints
 *
 */

$breakpoint-mobile: 320px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-hd: 1440px;
$breakpoint-period-column-small: 150px;
$breakpoint-day-column-small: 150px;

/*
 *
 * Spacing
 *
 */

$spacing-sm-0: 6px;
$spacing-sm-1: 8px;
$spacing-sm-2: 16px;
$spacing-sm-3: 24px;
$spacing-md-1: 32px;
$spacing-md-2: 40px;
$spacing-md-3: 56px;
$spacing-l-1: 72px;
$spacing-l-2: 80px;
$spacing-xl-1: 96px;
$spacing-xl-2: 120px;

/*
 *
 * Fonts
 *
 */

$fontsize-text-xsmall: 10px;
$fontsize-text-small: 12px;
$fontsize-text-normal: 14px;
$fontsize-text-medium: 16px;
$fontsize-text-large: 18px;
$fontsize-text-xlarge: 24px;
$fontsize-h1: 36px;
$fontsize-h2: 32px;
$fontsize-h3: 28px;
$fontsize-h4: 24px;
$fontsize-h5: 18px;
$fontsize-h6: 16px;

/*
 *
 * borders
 *
 */

$border-width-sm: 1px;
$border-width-md: 2px;
$border-width-lg: 5px;
$border-radius: 5px;
$border-radius-lg: 12px;
$border-radius-xl: 20px;

/*
 *
 * z-index
 *
 */

$error-wrapper-z-index: 0;
$user-menu-z-index: 1;
$taskboard-z-index: 1;
$overlay-z-index: 10;
$sidebar-z-index: 50;
$context-menu-z-index: 75;
$toast-z-index: 100;
$tooltip-z-index: 1000;

/*
 *
 * misc
 *
 */

$scrollbar-width: 8px;

// ***
$animation-speed-fast: 0.1s;
$animation-speed: 0.3s;
$animation-speed-long: 0.8s;

// ***
$card-box-shadow: colors.$box-shadow 0 5px 15px -5px;
$card-padding: 6px;

/*
 *
 * Opacity
 *
 */
 $disabled-opacity: 0.5;