@use 'abstracts/variables';

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: variables.$tooltip-z-index;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: variables.$tooltip-z-index;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: variables.$tooltip-z-index;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.mat-mdc-tooltip {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: variables.$fontsize-text-xsmall;
  padding-top: variables.$spacing-sm-0;
  padding-bottom: variables.$spacing-sm-0;
}

.mat-tooltip-handset {
  font-size: variables.$fontsize-text-normal;
  padding-top: variables.$spacing-sm-1;
  padding-bottom: variables.$spacing-sm-1;
}

.mat-tooltip-multiline {
  white-space: pre-line;

  /* stylelint-disable-next-line selector-class-pattern */
  .mdc-tooltip__surface {
    text-align: left;
  }
}

mat-tooltip-component {
  &:hover {
    pointer-events: none;
  }
}
