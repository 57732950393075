/*
This file contains base html element typography rules
*/

@use 'abstracts/variables';
@use 'abstracts/helpers';
@use 'abstracts/fonts';

$-primary-font: 'Roboto', sans-serif;

* {
  font-family: $-primary-font;
}

h1 {
  font-size: variables.$fontsize-h1;
}

h2 {
  font-size: variables.$fontsize-h2;
}

h3 {
  font-size: variables.$fontsize-h3;
}

h4 {
  font-size: variables.$fontsize-h4;
}

h5 {
  font-size: variables.$fontsize-h5;
}

h6 {
  font-size: variables.$fontsize-h6;
}

.portal-bold {
  font-weight: bold;
}

.text-xsmall {
  @include fonts.text-xsmall;
}

.text-small {
  @include fonts.text-small;
}

.text-normal {
  @include fonts.text-normal;
}

.text-medium {
  @include fonts.text-medium;
}

.text-large {
  @include fonts.text-large;
}

.text-xlarge {
  @include fonts.text-xlarge;
}

.text-ellipsis {
  @include helpers.text-ellipsis;
}
