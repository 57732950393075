@use 'abstracts/colors';
@use 'abstracts/animations';
@use 'abstracts/variables';

.sk-line {
  overflow: hidden;
  width: 100%;
  height: 24px;
  background-color: colors.$color-bg-dark;
  border-radius: variables.$border-radius;

  &.thick {
    height: 50px;
  }

  &::before {
    @include animations.skeleton-animation;
  }
}
