/*
This file contains helper mixins and functions for the project
*/

@use 'abstracts/colors';
@use 'abstracts/variables';

@mixin text-ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamp($nb: 1) {
  // https://css-tricks.com/almanac/properties/l/line-clamp/
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: $nb;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin portal-scroll-bar {
  ::-webkit-scrollbar {
    width: variables.$scrollbar-width;
    height: variables.$scrollbar-width;
  }

  ::-webkit-scrollbar-button {
    background: inherit;
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: colors.$color-text;
    border-radius: variables.$border-radius;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin portal-box-shadow() {
  box-shadow: 0 0 30px 0 colors.$box-shadow;
}

// Provides an hover pseudo element only for devices that support it.
// Prevents the persistent hover effect after a mobile 'touch' click for example.
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin full-screen {
  position: fixed;
  inset: 0;
}

.portal-text-decoration-none {
  text-decoration: none;
}

.al-disabled {
  opacity: 0.75;
  cursor: default;
}

$level-spacing: variables.$spacing-sm-1;

.portal-level {
  padding: $level-spacing;

  &-0 {
    padding-left: 0;
  }

  &-1 {
    padding-left: $level-spacing;
  }

  &-2 {
    padding-left: calc($level-spacing * 2);
  }

  &-3 {
    padding-left: calc($level-spacing * 3);
  }
}

.portal-align-content-center {
  align-content: center;
}

a,
a:hover,
a:visited,
a:link,
a:active {
  &.portal-not-a-link {
    text-decoration: none;
    color: unset;
  }
}
