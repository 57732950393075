@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/fonts';

/*
This file contains custom inputs styling
*/

.portal-input {
  &-no-style {
    color: unset;
    border: unset;
    border-radius: 0;
    background-color: unset;
    font-size: unset;
    font-weight: unset;
    padding: unset;

    &:focus-visible {
      outline: none;
    }

    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  font: inherit;
  padding: variables.$spacing-sm-1;
  background-color: colors.$color-bg;
  border-radius: variables.$border-radius;
  color: colors.$color-text;
  border: variables.$border-width-sm solid colors.$color-primary;
  outline: none;

  &.active {
    border-color: colors.$color-primary;
    color: colors.$color-primary;
  }

  &.invalid,
  &:invalid {
    border-color: colors.$color-red;
    color: colors.$color-red;
  }
}
