/*
 *
 * Dark Theme
 * Please update other theme files if anything is added or removed.
 *
 */

.dark-theme {
  --color-green100: #a7e6c0;
  --color-green300: #4db299;
  --color-green500: #63c3ab;

  --color-purple100: #544b89;
  --color-purple300: #6f63b4;
  --color-purple500: #8879de;

  --color-orange100: #ffecb3;
  --color-orange300: #ffbf62;

  --color-amber100: #ffecb3;
  --color-amber300: #ffd54f;
  --color-amber500: #ffc107;

  --color-red100: #ff9696;
  --color-red300: #f1685e;

  --color-black: #fff;
  --color-white: #3e3e42;

  --color-grey800: #e8e9eb;
  --color-grey600: #9f9f9f;
  --color-grey300: #1e1e1e;
  --color-grey200: #2d2d30;
  --color-grey100: #252526;

  --color-shadow: #000;

  --color-new: #bdbdbd;
  --color-waiting-approval: #e4a11e;
  --color-refused: #cb413f;
  --color-approved: #6db82c;
  --color-transferred: #a5308b;
  --color-transferred-no-approval: #6375d6;
  --color-edited-after-transfer: #3c3c3c;

  // change the animation color for the skeleton
  --color-loading-gradient1: hsl(0deg 0% 25% / 0%);
  --color-loading-gradient2: hsl(0deg 0% 25% / 60%);

  .main-logo,
  .brand-name-image {
    filter: invert(100%);
  }
}
