@use 'abstracts/variables';
@use 'abstracts/helpers';
@use 'abstracts/colors';
@use 'abstracts/animations';

$tree-spacing: variables.$spacing-sm-1;

.portal-remove-indent {
  &.ag-theme-alpine {
    .ag-cell-wrapper.ag-row-group[class*='ag-row-group-indent'],
    .ag-cell-wrapper.ag-row-group-leaf-indent[class*='ag-row-group-indent'] {
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.portal-grid-no-borders {
  .ag-sticky-top {
    background-color: colors.$color-bg;
  }

  .ag-row {
    border: 0;
    background: none;
  }

  .ag-layout-normal {
    background: none;
    border: none;
  }
}

.portal-grid-indent-levels {
  .ag-row-level-1 {
    .ag-cell {
      padding-left: $tree-spacing;
    }
  }

  .ag-row-level-2 {
    .ag-cell {
      padding-left: $tree-spacing * 2;
    }
  }

  .ag-row-level-3 {
    .ag-cell {
      padding-left: $tree-spacing * 3;
    }
  }
}
