/*
This file contains custom buttons styling
*/

@use 'abstracts/colors';
@use 'abstracts/variables';
@use 'abstracts/fonts';
@use 'abstracts/helpers';
@use 'abstracts/animations';

$btn-spacing-sm: 5px;
$btn-spacing-md: 10px;
$btn-spacing-lg: 20px;
$btn-height-sm: 32px;
$btn-height-md: 46px;
$btn-height-lg: 60px;
$btn-padding-sm: 0 12px;
$btn-padding-md: 0 24px;
$btn-padding-lg: 0 32px;

// Common button
.portal-button,
.portal-tile-button,
.portal-icon-button {
  @include fonts.text-large;
  @include animations.transition-ease(filter, variables.$animation-speed-fast);

  height: $btn-height-md;
  padding: $btn-padding-md;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $btn-spacing-md;
  border: 0;
  filter: brightness(1);

  .btn-icon {
    display: flex;
    font-size: variables.$iconsize-md;
  }

  &.btn-small {
    @include fonts.text-normal;

    height: $btn-height-sm;
    padding: $btn-padding-sm;
    gap: $btn-spacing-sm;

    .btn-icon {
      font-size: variables.$iconsize-sm;
    }
  }

  &.btn-large {
    @include fonts.text-xlarge;

    height: $btn-height-lg;
    padding: $btn-padding-lg;
    gap: $btn-spacing-lg;

    .btn-icon {
      font-size: variables.$iconsize-l;
    }
  }
}

// Portal default button
.portal-button {
  color: colors.$color-bg-light;
  background-color: colors.$color-primary;
  border-radius: variables.$border-radius;
  user-select: none;
  cursor: pointer;

  &.secondary {
    color: colors.$color-text;
    background: colors.$color-bg-dark;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not([disabled]) {
    @include helpers.hover {
      filter: brightness(85%);
    }

    &:active {
      filter: brightness(75%);
    }
  }
}

// Portal icon button
.portal-icon-button {
  width: $btn-height-md;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden; // Prevents the button from being stretched by the content (not a circle anymore)
  color: colors.$color-bg-light;
  background-color: colors.$color-primary;
  user-select: none;
  cursor: pointer;

  &.secondary {
    color: colors.$color-text;
    background: colors.$color-bg-dark;
  }

  &.btn-small {
    width: $btn-height-sm;
  }

  &.btn-large {
    width: $btn-height-lg;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not([disabled]) {
    @include helpers.hover {
      filter: brightness(85%);
    }

    &:active {
      filter: brightness(75%);
    }
  }
}

// Portal tile button
.portal-tile-button {
  color: colors.$color-text;
  background-color: colors.$color-bg;
  border-radius: 0;
  user-select: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:not([disabled]) {
    @include helpers.hover {
      filter: brightness(90%);
    }

    &:active {
      filter: brightness(80%);
    }
  }
}
