/*
This file contains font declarations and font-related mixins
*/

@use 'abstracts/variables';

@import '@fontsource/roboto/latin-400.css';
@import '@fontsource/roboto/latin-700.css';

@mixin text-xsmall {
  font-size: variables.$fontsize-text-xsmall;
}

@mixin text-small {
  font-size: variables.$fontsize-text-small;
}

@mixin text-normal {
  font-size: variables.$fontsize-text-normal;
}

@mixin text-medium {
  font-size: variables.$fontsize-text-medium;
}

@mixin text-large {
  font-size: variables.$fontsize-text-large;
}

@mixin text-xlarge {
  font-size: variables.$fontsize-text-xlarge;
}
