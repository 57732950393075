@charset 'utf-8';

// Vendor
@forward 'vendor/__vendor-dir';

// Abstracts
@forward 'abstracts/__abstracts-dir';

// Base Styles
@forward 'base/__base-dir';

// Components
@forward 'components/__components-dir';

// Layouts
@forward 'layouts/__layouts-dir';

// Themes
@forward 'default/default-theme';
@forward 'dark/dark-theme';

/*
 All of style folders are imported globally only once in the project, from this file.
 This single entrypoint can also be used to import variables, mixins, extend classes etc.. in every
 component file with the following : @use 'styles' as *;
 */
